<script setup>

import { ref, onBeforeMount } from "vue";
import { useUserStore } from "@/stores/user";
import TransportLayout from "@/layouts/TransportLayout.vue";
import useTransportOrder from "@/hooks/useTransportOrders.js";
import useBillingRates from '@/hooks/useBillingRates';
import { useRoute } from "vue-router/auto";
import { useToast } from "vue-toastification";

const userStore = useUserStore();
const { fetchRates } = useBillingRates();
let rates = ref(null);
const toast = useToast();

const items = ref([]);

const {
    fetchTransportOrder,
    order,
    fetchBillableActivities,
    saveBillableActivities,
} = useTransportOrder();

const route = useRoute();
onBeforeMount(async () => {
    await fetchTransportOrder(route.params.id);
    if (order.value.bill_id) {
        items.value = await fetchBillableActivities(order.value.bill_id);
    }
    rates.value = await fetchRates();
});

function lineAmount(item) {
    const total = item.cost * item.qty
    return total < item.min_total ? item.min_total : total
}

function editItem(item, index) {
    item.editing = true
}

async function submit(item) {
    if (!item.rate_id) return toast.error("Rate is required");
    if (!item.qty) return toast.error("Quantity is required");

    const order_billable_activities = [{
        id: item.id || undefined,
        rate_id: item.rate_id,
        qty: item.qty,
        cost: item.cost,
        body: item.body
    }]

    const res = await saveBillableActivities(order.value.bill_id, { order_billable_activities });
    if (res) {
        items.value = await fetchBillableActivities(order.value.bill_id);
    }
}

async function deleteItem(item, index) {
    let res = null;
    if (item.id) {
        const remove_order_billable_activities = [item.id]
        res = await saveBillableActivities(order.value.bill_id, { remove_order_billable_activities })
    } 
    if (!item.id || res) {
        items.value.splice(index, 1);
    }
}

function attachRate(item, rate) {
    item.rate = rate
    item.rate_id = rate.id
    item.cost = rate.cost
}

function addItem() {
    items.value.push({
        rate: null,
        rate_id: null,
        cost: 0,
        qty: 1,
        error: false,
        editing: true,
        body: '',
    })
}

</script>

<template>
    <TransportLayout v-if="order" :transportOrder="order">
        <div v-cloak style="width:100%;">
            <div class="ml-4 mr-6 responsive-margin">
                <div>
                    <div class="ma-2" v-if="userStore.user && userStore.user.tenant_id == 1 && order">
                        <template v-if="order.order_type_id == 3">
                            <v-alert outlined type="warning" color="warning" v-if="!order.bill_id">
                                This special order has not been processed yet. When it is processed, billing items will
                                be added.
                                Adding items beforehand will override only those items during processing.
                            </v-alert>
                            <v-alert outlined type="warning" v-else>
                                This order has already been processed. Please
                                <a :href="'/billings?delivery_id=' + order.delivery_id">click here</a> to see the bill.
                            </v-alert>
                        </template>
                        <template v-else>
                            <v-alert outlined type="warning" v-if="!order.bill_id">
                                This order has not been processed yet. When it is processed, billing items will be
                                added.
                                Adding items beforehand will override only those items during processing.
                                <a href="#">Process it now</a>
                            </v-alert>
                            <v-alert outlined type="warning" v-else>
                                This order has already been processed. Please see
                                <a :href="'/billings?' + order.bill_id">BILL # {{ order.bill_id }}</a>
                            </v-alert>
                        </template>
                    </div>
                    <template v-if="order.bill_id">
                        <div class="ma-8 text-center" v-if="items.length == 0">
                            No items yet.
                        </div>
                        <table class="ma-3 mt-6 w-100 responsive-table" style="border-top: 0;">
                            <tbody>
                                <tr v-for="(item, index) in items" class="border-bottom item-row" :key="index"
                                    :class="{ 'table-danger': item.error }" @click="item.open = true">
                                    <td class="index-column">{{ index + 1 }}</td>
                                    <td>
                                        <div v-if="!item.editing" class="d-flex justify-content-between item-row"
                                            @click="item.editing = true">
                                            <div class="item-info">
                                                <div class="rate-name">
                                                    {{ item.rate.code }} - {{ item.rate.name }}
                                                </div>
                                                <small>{{ item.body }}</small>
                                            </div>
                                            <div class="item-amount">
                                                <span>{{ item.qty }} x \${{ parseFloat(item.cost).toFixed(3) }}</span>
                                                <span>=</span>
                                                <span>{{ (item.qty * parseFloat(item.cost).toFixed(3)).toFixed(3) }}</span>
                                            </div>
                                        </div>
                                        <div class="d-flex flex-column mt-6" v-if="item.editing" style="width:100%;">
                                            <div class="d-flex fields responsive-fields">
                                                <div class="mr-2" style="flex: auto;">
                                                    <div id="app_page">
                                                        <v-autocomplete attach="#app_page" density="compact" :items="rates"
                                                            v-model="item.rate" item-value="id" item-title="name"
                                                            placeholder="Select Item or Service" return-object
                                                            @update:modelValue="rate => attachRate(item, rate)" variant="outlined" />
                                                    </div>
                                                </div>
                                                <div class="mr-2" style="flex: 1">
                                                    <v-text-field density="compact" type="number" min="0"
                                                        placeholder="Enter Quantity" v-model="item.qty"
                                                        variant="outlined" />
                                                </div>
                                                <div class="d-flex justify-content-center align-items-center">
                                                    x &nbsp;&nbsp;
                                                </div>
                                                <div class="ml-2 mr-2" style="flex: 1">
                                                    <v-text-field density="compact" type="number" min="0"
                                                        v-model="item.cost" variant="outlined" />
                                                </div>
                                                <div class="d-flex justify-content-center align-items-center">
                                                    =
                                                </div>
                                                <div class="ml-2 mr-0 pr-0" style="flex: 1">
                                                    <input class="form-control form-control-sm qty-control" type="number"
                                                        min="0" :value="lineAmount(item).toFixed(3)" disabled readonly
                                                        style="text-align: right;background: transparent;border:0;padding: 0;" />
                                                </div>
                                            </div>
                                            <div class="mt-2">
                                                <v-text-field density="compact" v-model="item.body"
                                                    placeholder="Enter item description or details" variant="outlined" />
                                            </div>
                                        </div>
                                    </td>
                                    <td class="d-flex mt-4 mr-4 responsive-controls">
                                        <v-btn icon @click="deleteItem(item, index)" size="small" variant="text">
                                            <v-icon>mdi-trash-can</v-icon>
                                        </v-btn>
                                        <v-btn icon @click="item.editing = true" v-if="!item.editing" size="small"
                                            variant="text">
                                            <v-icon>mdi-pencil</v-icon>
                                        </v-btn>

                                        <v-btn icon @click="submit(item)" v-if="item.editing" size="small" variant="text">
                                            <v-icon>mdi-content-save</v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="text-center mb-4">
                            <v-btn @click.prevent="addItem" text>
                                <v-icon>mdi-plus</v-icon>
                                Add Item
                            </v-btn>
                        </div>
                    </template>
                    <template v-else>
                        <div class="ma-8 text-center">There are no available bills.</div>
                    </template>
                </div>
            </div>
        </div>

    </TransportLayout>
</template>

<style scoped>
@media screen and (max-width: 768px) {
    .responsive-margin {
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .ma-2 {
        margin: 0.5rem;
    }

    .ma-8,
    .ma-3 {
        margin: 1rem;
    }

    .mt-6 {
        margin-top: 2rem;
    }

    .w-100 {
        width: 100%;
    }

    .item-info {
        width: 100%;
    }

    .item-amount {
        text-align: right;
        width: 100%;
        margin-top: 1rem;
    }

    .item-row {
        flex-direction: column;
        align-items: flex-start;
    }

    .responsive-fields {
        flex-direction: column;
    }

    .responsive-controls {
        flex-direction: column;
        align-items: flex-start;
        margin-top: 0.5rem;
    }

    .index-column {
        width: 30px;
    }
}
</style>